import React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import Layout from '../components/Layout/Layout'
import FormVolunteer from '../components/FormVolunteer'

const ns = `join`

const JoinPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter: content },
  } = data
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <Layout>
      <div className={rootClassnames}>
        <div className={`${ns}__container`}>
          <h1 className="page--title page--tittle-noborder">{content.title}</h1>
          <div className={`${ns}__content-container`}>
            <div className={`${ns}__sections`}>
              <FormVolunteer
                buttonLabel={content.button_label}
                tagsList={content.tags_list}
                successMessage={content.success_message}
                errorMessage={content.error_message}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JoinPage

export const joinPageQuery = graphql`
  query JoinPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        button_label
        tags_list
        success_message
        error_message
      }
    }
  }
`
