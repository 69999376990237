export default function validateInfo(values) {
  const errors = {}

  if (!values.first_name.trim()) {
    errors.first_name = 'First name required'
  }

  if (!values.last_name.trim()) {
    errors.last_name = 'Last name required'
  }

  if (!values.email) {
    errors.email = 'Email required'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid'
  }

  return errors
}
