import axios from 'axios'
import { useState, useEffect } from 'react'

const useForm = (url, validateInfo, tagsList, successMessage, errorMessage) => {
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    email_opt_in: true,
    phone: '',
    do_not_call: false,
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    birthdate: '',
    tags: tagsList,
  })

  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  const handleChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }
  const handleCheckboxChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.checked,
    })
  }

  const addPerson = async () => {
    try {
      const request = await axios({
        url: `${url}/.netlify/functions/addPerson`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: values,
      })
      setLoading(false)
      setMessage(`${successMessage}`)
      setValues({
        first_name: '',
        last_name: '',
        email: '',
        email_opt_in: true,
        phone: '',
        do_not_call: false,
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        birthdate: '',
      })
    } catch (error) {
      setLoading(false)
      setMessage(`${errorMessage}`)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    setErrors(validateInfo(values))
    setIsSubmitting(true)
  }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setLoading(false)
    }
    if (Object.keys(errors).length === 0 && isSubmitting) {
      addPerson()
    }
  }, [errors])

  return {
    handleChange,
    handleCheckboxChange,
    values,
    handleSubmit,
    errors,
    loading,
    message,
  }
}

export default useForm
