import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import useForm from '../../util/useForm'
import validateInfo from '../../util/validateInfo'

const ns = `volunteer-form`

const FormVolunteer = ({
  buttonLabel,
  tagsList,
  successMessage,
  errorMessage,
}) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  const [url, setUrl] = useState('')

  useEffect(() => {
    setUrl(window.location.origin)
  }, [])

  const {
    handleChange,
    handleCheckboxChange,
    values,
    handleSubmit,
    errors,
    loading,
    message,
  } = useForm(url, validateInfo, tagsList, successMessage, errorMessage)

  return (
    <div className={rootClassnames}>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-12 col-md">
            <label htmlFor="first_name">
              {/* First Name */}
              <input
                name="first_name"
                type="text"
                className="form-control"
                id="first_name"
                placeholder="Enter first name"
                value={values.first_name}
                onChange={handleChange}
              />
              {errors.first_name && (
                <div className="invalid-feedback">{errors.first_name}</div>
              )}
            </label>
          </div>
          <div className="form-group col-12 col-md">
            <label htmlFor="last_name">
              {/* Last Name */}
              <input
                name="last_name"
                type="text"
                className="form-control"
                id="last_name"
                placeholder="Enter last name"
                value={values.last_name}
                onChange={handleChange}
              />
              {errors.last_name && (
                <div className="invalid-feedback">{errors.last_name}</div>
              )}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12 col-md">
            <label htmlFor="email">
              {/* Email address */}
              <input
                name="email"
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email address"
                value={values.email}
                onChange={handleChange}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </label>
          </div>
          <div className="form-group col-12 col-md">
            <label htmlFor="phone">
              {/* Phone Number */}
              <input
                name="phone"
                type="tel"
                className="form-control"
                id="phone"
                placeholder="Enter phone number"
                value={values.phone}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        <div className="row checks">
          <div className="form-group col-12 col-md">
            <div className="form-check">
              <label className="form-check-label" htmlFor="email_opt_in">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="email_opt_in"
                  name="email_opt_in"
                  checked={values.email_opt_in}
                  onChange={handleCheckboxChange}
                />
                opt into Emails
              </label>
            </div>
          </div>

          <div className="form-group col-12 col-md">
            <div className="form-check">
              <label className="form-check-label " htmlFor="do_not_call">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="do_not_call"
                  name="do_not_call"
                  checked={values.do_not_call}
                  onChange={handleCheckboxChange}
                />
                opt into phone calls and text
              </label>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-12 col-md-6">
            <label htmlFor="address1">
              {/* Address */}
              <input
                type="text"
                className="form-control"
                id="address1"
                placeholder="Address"
                name="address1"
                value={values.address1}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="address2">
              {/* Address 2 */}
              <input
                type="text"
                className="form-control"
                id="address2"
                placeholder="Apartment, studio, or floor"
                name="address2"
                value={values.address2}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="city">
              {/* City */}
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
                placeholder="City"
                value={values.city}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="form-group col-6 col-md-4">
            <label htmlFor="state">
              {/* State */}
              <input
                type="text"
                className="form-control"
                id="state"
                name="state"
                placeholder="State"
                value={values.state}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="form-group col-6 col-md-2">
            <label htmlFor="zip">
              {/* Zip */}
              <input
                type="text"
                className="form-control"
                id="zip"
                name="zip"
                placeholder="Zip"
                value={values.zip}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="birthdate">
            Birthday
            <input
              name="birthdate"
              type="date"
              className="form-control"
              id="birthdate"
              placeholder="mm/dd/yyyy"
              value={values.birthdate}
              onChange={handleChange}
            />
          </label>
        </div>
        <button className="button m-auto" type="submit">
          {loading ? 'Submitting Form' : buttonLabel}
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
  )
}

export default FormVolunteer
